@keyframes slideInRight {
    from {
        transform: translate3d(100%, 0, 0);
    }

    to {
        transform: translate3d(0, 0, 0);
    }
}

@keyframes slideOutRight {
    from {
        transform: translate3d(0, 0, 0);
    }

    to {
        transform: translate3d(100%, 0, 0);
    }
}

@keyframes slideInUp {
    from {
        transform: translate3d(0, 100%, 0);
    }

    to {
        transform: translate3d(0, 0, 0);
    }
}

@keyframes slideInDown {
    from {
        transform: translate3d(0, -100%, 0);
    }

    to {
        transform: translate3d(0, 0, 0);
    }
}
