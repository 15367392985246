.dialog {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 1000;
    display: none;
    margin: auto;
    border-radius: var(--border-radius);
}

.dialog[open] {
    display: block;
    animation: dialogSlideIn var(--animation-duration) var(--animation-out-back);
}

.dialog-close {
    position: absolute;
    top: -24px;
    left: -24px;
    width: 48px;
    height: 48px;
    padding: 0;
    color: var(--theme-color);
    list-style-type: none;
}

.dialog-close::-webkit-details-marker {
    display: none;
}

.dialog-close::marker {
    display: none;
}

.dialog-close::before {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: -1;
    content: "";
}

.dialog-close > svg {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    width: 36px;
    height: 36px;
    margin: auto;
}

.loadaudio-dialog {
    width: 500px;
    max-width: calc(100% - 36px);
    height: 10rem;
    max-height: 100%;
    color: var(--black);
    background-color: var(--semi-white-color);
    border: 1px solid var(--theme-color);
    box-shadow: 0 0 1rem var(--theme-color), 0 0 0 100vmax #00000088;
}

.loadaudio-body {
    display: flex;
    justify-content: space-around;
    box-sizing: border-box;
    padding: 10px;
}

.tab {
    display: inline-block;
}

.loadaudio-tab {
    width: 100%;
    text-align: center;
}

.loadaudio-tab > [type="radio"] {
    display: none;
}

.loadaudio-content {
    position: absolute;
    left: 0;
    width: 100%;
    height: 100px;
    visibility: hidden;
}

.loadaudio-tab > label {
    display: inline-block;
    width: 100%;
    padding: 8px 0;
    border: 1px solid transparent;
    border-bottom: 1px solid var(--theme-color);
    border-top-left-radius: 6px;
    border-top-right-radius: 6px;
    user-select: none;
}

.loadaudio-tab :checked ~ label {
    background-color: #ffffffdd;
    border: 1px solid var(--theme-color);
    border-bottom-color: transparent;
}

.loadaudio-tab :checked ~ label ~ .loadaudio-content {
    visibility: visible;
}

.audio-input-tip,
.audio-input-form {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    padding: 16px;
    transition: transform var(--transition-duration) ease-in-out;
}

.audio-input-tip:hover {
    transform: scale(1.2);
}

.audio-input-form > input[type="url"] {
    flex: 1 0 auto;
    width: 60%;
    margin: 8px;
    font-size: 1.5rem;
    background: 0;
    border: 0;
    border-bottom: 1px solid var(--theme-color);
}

.audio-input-form > input[type="submit"] {
    border: 0;
    appearance: none;
}

@keyframes dialogSlideIn {
    from {
        transform: translate(0, 100%);
        opacity: 0;
    }

    50% {
        opacity: 1;
    }
}
