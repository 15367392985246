.preferences {
    width: 100%;
    user-select: none;
}

.preferences > ul {
    max-width: 1024px;
    margin: auto;
}

.preferences li {
    width: 100%;
    padding: 0 8px;
}

.preferences li:nth-child(even) {
    background-color: #00000022;
}

.list-item {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
    max-width: 768px;
    min-height: 3.5rem;
    margin: auto;
}

.list-item > span,
.list-item > form {
    margin: 8px 0;
}

.list-item > .link {
    padding: 0;
}

.toggle-switch {
    --switch-theme-rgb: var(--theme-rgb);
    --switch-margin: 0;
    --bar-height: 20px;
    --bar-width: 52px;
    --knob-size: 28px;

    justify-content: center;
    width: var(--switch-width);
    height: var(--knob-size);
}

@supports (-ms-ime-align: auto) {
    .toggle-switch {
        --switch-offset: 8px;
        --switch-width: 60px;
    }

    .toggle-switch-label::after {
        box-shadow: 0 0 8px #11111188;
    }

    :hover > .toggle-switch-label::after {
        box-shadow: 0 0 8px #11111188, 0 0 0 14px rgba(var(--theme-rgb), 0.2);
    }

    .toggle-switch-label {
        --knob-x: -16px;
    }

    :checked + .toggle-switch-label {
        --knob-x: 16px;
    }
}

.color-picker {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    min-width: 30px;
    height: 30px;
    margin: 2px;
    line-height: normal;
    vertical-align: middle;
    border-radius: var(--border-radius);
}

.color-picker.hash {
    color: var(--theme-contrast-color);
}

.color-picker.checked::before {
    color: var(--theme-contrast-color);
    content: "\2714\FE0F";
}

.user-color-input-text {
    flex: 1 1 74px;
    grid-column: span 2;
    align-self: stretch;
    justify-self: stretch;
    min-width: 60px;
    margin: 2px;
    background: 0;
    border: 0;
    border-bottom: 1px solid var(--theme-color);
}

.user-color-label {
    color: var(--theme-contrast-color);
}

.theme-color-span {
    display: none;
}

.user-color-label,
.user-color-input-text {
    display: none;
}

@media (min-width: 768px) {
    .user-color-label,
    .user-color-input-text {
        display: inline-flex;
    }
}

.format-example-time,
.format-example-text {
    vertical-align: middle;
}

.format-example-text {
    display: inline;
    color: var(--theme-contrast-color);
    white-space: pre-wrap;
    background-color: var(--theme-color);
}

.option-select > select {
    padding-right: 16px;
    text-align-last: right;
    background-color: transparent;
    border: none;
    outline: 0;
    appearance: none;
}

.option-select > select > option {
    color: var(--font-color);
    background-color: var(--background-color);
}

::-ms-expand {
    display: none;
}

.option-select {
    position: relative;
}

.option-select::after {
    position: absolute;
    right: 0;
    color: var(--theme-color);
    content: "\25BC";
    pointer-events: none;
}

input[type="number"] {
    width: 4rem;
    height: 30px;
    text-align: right;
    background: 0;
    border: 0;
    border-bottom: 1px solid var(--theme-color);
    border-radius: 0;
}

.akari-hide-wall {
    position: fixed;
    right: 0;
    bottom: 32px;
    z-index: -1;
    width: 256px;
    height: 256px;
    transform: translateX(100%);
    opacity: 0.8;
    animation: akariSlideIn 500ms var(--animation-out-back) 3s forwards, akariFloat 20s ease-in-out 4s infinite;
    stroke-width: 4px;
}

@keyframes akariSlideIn {
    from {
        transform: translateX(100%);
    }

    to {
        transform: translateX(34%);
    }
}

@keyframes akariFloat {
    0% {
        transform: translate(34%, 0);
    }

    50% {
        transform: translate(34%, 1rem);
    }

    100% {
        transform: translate(34%, 0);
    }
}

.current-theme-color {
    font-family: Consolas, Monaco, monospace;
    vertical-align: middle;
}

.dropdown {
    position: relative;
}

.dropdown > summary {
    list-style-type: none;
}

.dropdown > summary::-webkit-details-marker {
    display: none;
}

.dropdown > summary::marker {
    display: none;
}

.dropdown > summary::after {
    color: var(--theme-color);
    vertical-align: middle;
    content: "\25BC";
    pointer-events: none;
}

.dropdown[open] > summary::before {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 80;
    display: block;
    cursor: default;
    content: "";
}

.dropdown-body {
    position: absolute;
    top: 100%;
    right: 0;
    z-index: 100;
    margin: 8px;
    padding: 8px;
    background-color: var(--background-color);
    border: 1px solid var(--theme-color);
    border-radius: var(--border-radius);
    box-shadow: 0 0 1rem var(--theme-color);
}

.dropdown-body::before {
    position: absolute;
    right: 74px;
    bottom: 100%;
    margin-left: -8px;
    border-color: transparent transparent var(--theme-color) transparent;
    border-style: solid;
    border-width: 8px;
    content: "";
}

.color-wall {
    display: none;
    grid-row-gap: 4px;
    grid-column-gap: 4px;
    grid-template-columns: repeat(5, 1fr);
    align-items: center;
    justify-items: center;
    width: 200px;
}

[open] > .color-wall {
    display: flex;
    display: grid;
    flex-wrap: wrap;
}

.pseudo-hidden {
    position: absolute;
    visibility: hidden;
}
