.aside-panel {
    position: fixed;
    right: 0;
    bottom: 64px;
    margin-bottom: env(safe-area-inset-bottom);
    background-color: var(--semi-black-color);
    border-radius: 8px 0 0 8px;
    transform: translateX(100%);
    animation: slideInRight var(--animation-duration) ease 1s forwards;
}

.on-screen-button + .aside-panel {
    bottom: 100px;
}

.aside-button {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    box-sizing: content-box;
    width: 50px;
    height: 50px;
    margin: 0;
    padding: 0;
    padding-right: env(safe-area-inset-right);
    color: var(--white);
    line-height: 1;
}

.aside-button > svg {
    width: 30px;
    height: 30px;
    margin: auto;
    fill: currentColor;
}

.aside-button:hover > svg {
    filter: drop-shadow(0 0 4px var(--theme-color));
}

.syncmode-button {
    border-bottom: 1px solid var(--white);
    border-top-left-radius: 8px;
}

.syncmode-button.highlight {
    color: var(--theme-contrast-color);
    background-color: var(--theme-color);
}

.syncmode-button.highlight .lock-open {
    visibility: hidden;
}

.syncmode-button.select .lock-close {
    visibility: hidden;
}
